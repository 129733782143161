import React, { useState } from 'react';
import Headers from './headers';
import Image from '../assets/bg-image.jpeg';
import Footer from './footer';

const Audiomerge = () => {
    const [audioFile1, setAudioFile1] = useState(null);
    const [audioFile2, setAudioFile2] = useState(null);
    const [additionalAudioFiles, setAdditionalAudioFiles] = useState([]);
    const [mergedFileUrl, setMergedFileUrl] = useState(null);

    const handleFileChange = (event, setFile) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
        }
    };

    const handleAdditionalFileChange = (event) => {
        const files = Array.from(event.target.files);
        setAdditionalAudioFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const handleConvert = async () => {
        const formData = new FormData();
        if (audioFile1) formData.append('files', audioFile1);
        if (audioFile2) formData.append('files', audioFile2);
        additionalAudioFiles.forEach(file => formData.append('files', file));

        try {
            const response = await fetch('http://localhost:9000/api/v1/merge-audios/', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (response.ok) {
                setMergedFileUrl(data.file_path);
            } else {
                console.error(data.error);
            }
        } catch (error) {
            console.error("Error merging audio files:", error);
        }
    };

    const isConvertDisabled = !audioFile1 && !audioFile2 && additionalAudioFiles.length === 0;

    return (
        <div>
            <Headers />
            <div className="relative px-4 py-10 md:px-10 lg:px-24 xl:px-36">
                <div className="relative h-auto w-full rounded-md overflow-hidden">
                    <div
                        className="absolute inset-0"
                        style={{
                            backgroundImage: `url(${Image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            opacity: 0.3,
                            zIndex: -1,
                        }}
                    />
                    <div className="relative z-10 py-8 px-4">
                        <div className="text-3xl md:text-4xl lg:text-5xl font-black text-center" style={{ fontFamily: 'Lexend Deca' }}>
                            Online Audio Merge
                        </div>
                        <div className="h-[2px] w-full bg-gray-500 my-4"></div>
                        <div className="py-5">
                            <table className="w-full max-w-[900px] border-separate border-spacing-4 mx-auto">
                                <tbody>
                                    <tr>
                                        <td className="text-xl font-normal text-start font-lexend" style={{ color: '#313131' }}>
                                            Audio File 1
                                        </td>
                                        <td className="flex items-center gap-4">
                                            <input
                                                type="file"
                                                accept="audio/*"
                                                id="audio-file-1"
                                                className="hidden"
                                                onChange={(e) => handleFileChange(e, setAudioFile1)}
                                            />
                                            <button
                                                className='px-4 py-2 text-white rounded-md'
                                                style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
                                                onClick={() => document.getElementById('audio-file-1').click()}
                                            >
                                                Choose Files
                                            </button>
                                            <div>{audioFile1 ? audioFile1.name : 'No Chosen Files'}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl font-normal text-start font-lexend" style={{ color: '#313131' }}>
                                            Audio File 2
                                        </td>
                                        <td className="flex items-center gap-4">
                                            <input
                                                type="file"
                                                accept="audio/*"
                                                id="audio-file-2"
                                                className="hidden"
                                                onChange={(e) => handleFileChange(e, setAudioFile2)}
                                            />
                                            <button
                                                className='px-4 py-2 text-white rounded-md'
                                                style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
                                                onClick={() => document.getElementById('audio-file-2').click()}
                                            >
                                                Choose Files
                                            </button>
                                            <div>{audioFile2 ? audioFile2.name : 'No Chosen Files'}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl font-normal text-start font-lexend" style={{ color: '#313131' }}>
                                            More Audio?
                                        </td>
                                        <td className="flex items-center gap-4">
                                            <input
                                                type="file"
                                                accept="audio/*"
                                                id="additional-audio-files"
                                                className="hidden"
                                                multiple
                                                onChange={handleAdditionalFileChange}
                                            />
                                            <button
                                                className='px-4 py-2 text-white rounded-md'
                                                style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
                                                onClick={() => document.getElementById('additional-audio-files').click()}
                                            >
                                                Add More Audio
                                            </button>
                                            <div>
                                                {additionalAudioFiles.length > 0
                                                    ? additionalAudioFiles.map((file, index) => (
                                                        <div key={index}>{file.name}</div>
                                                    ))
                                                    : 'No Chosen Files'}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-10 flex justify-center">
                            <button
                                className={`bg-green-500 px-14 py-2 text-white rounded-md font-semibold ${isConvertDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                style={{ fontFamily: 'Montserrat' }}
                                onClick={handleConvert}
                                disabled={isConvertDisabled}
                            >
                                Convert
                            </button>
                        </div>

                        {mergedFileUrl && (
                            <div className="flex flex-col items-center mt-4">
                                <audio controls className="mb-4">
                                    <source src={mergedFileUrl} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                </audio>
                                <a href={mergedFileUrl} download>
                                    <button className='bg-blue-500 px-14 py-2 text-white rounded-md font-semibold'>
                                        Download
                                    </button>
                                </a>
                            </div>
                        )}

                        {/* How To Use and Remarks sections here... */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Audiomerge;
