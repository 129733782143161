// src/AboutUs.js
import React from 'react';
import './AboutUs.css'; // Ensure to create this CSS file for custom styles
import Headers from './headers';
import Footer from './footer';
import Image from '../assets/bg-image.jpeg';
import humanImage from '../assets/developers/human.jpg';
import humanImage2 from '../assets/developers/human2.jpg';
import humanImage3 from '../assets/developers/human3.jpg';
import humanImage4 from '../assets/developers/human3.jpg';
import humanImage5 from '../assets/developers/human3.jpg';
import humanImage6 from '../assets/developers/human3.jpg';

const teamMembers = [
    {
        name: "Shagun Tomar",
        image: humanImage, // Replace with the actual image path
        role: "Developer & Product Owner",
        description: "Shagun is passionate about creating seamless user experiences and innovative solutions."
    },
    {
        name: "Saurabh Singh",
        image: humanImage2, // Replace with the actual image path
        role: "Developer",
        description: "Saurabh specializes in frontend development and brings ideas to life with code."
    },
    {
        name: "Kanhaiya",
        image: humanImage3, // Replace with the actual image path
        role: "Developer",
        description: "Kanhaiya is focused on backend development, ensuring everything runs smoothly."
    },
    {
        name: "Vikram",
        image: humanImage4, // Replace with the actual image path
        role: "Developer",
        description: "Vikram is dedicated to improving performance and enhancing user satisfaction."
    },
    {
        name: "Mohd Zaid",
        image: humanImage5, // Replace with the actual image path
        role: "Developer",
        description: "Mohd Zaid is an expert in API integrations and data management."
    },
    {
        name: "Shekhar",
        image: humanImage6, // Replace with the actual image path
        role: "Developer",
        description: "Shekhar focuses on ensuring quality and delivering exceptional results."
    },
];

const AboutUs = () => {
    return (
        <div>
            <Headers />
            <div className="relative px-4 py-10 md:px-10 lg:px-24 xl:px-36">
                <div className="relative h-auto w-full rounded-md overflow-hidden">
                    <div
                        className="absolute inset-0"
                        style={{
                            backgroundImage: `url(${Image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            opacity: 0.3,
                            zIndex: -1,
                        }}
                    />
        <div className="about-us-container p-4 md:p-8">
            <h1 className="text-2xl font-bold mb-4 text-center">About Us</h1>
            <p className="mb-4 text-center">
                Welcome to The Converter Hub, your go-to destination for all your audio and video conversion needs! 
                Our mission is to provide a simple, fast, and reliable online service that allows you to convert 
                audio and video files with ease.
            </p>
            <h2 className="text-xl font-semibold mt-6 mb-2 text-center">Our Vision</h2>
            <p className="mb-4 text-center">
                At The Converter Hub, we envision a world where everyone can access high-quality audio and video 
                conversion tools without any technical barriers. Our platform is designed to be user-friendly, 
                so you can focus on what really matters: your content.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2 text-center">Meet Our Team</h2>
            <div className="team-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {teamMembers.map((member) => (
                    <div key={member.name} className="team-member p-4 border rounded-lg shadow-md text-center">
                        <img src={member.image} alt={member.name} className="team-member-image w-32 h-32 rounded-full mx-auto mb-2" />
                        <h3 className="font-bold text-lg">{member.name}</h3>
                        <p className="text-sm text-gray-500">{member.role}</p>
                        <p className="mt-2">{member.description}</p>
                    </div>
                ))}
            </div>

            <h2 className="text-xl font-semibold mt-6 mb-2 text-center">Get in Touch</h2>
            <p className="text-center mb-4">
                We would love to hear from you! If you have any questions, feedback, or suggestions, feel free 
                to contact us at info@theconverterhub.com.
            </p>
        </div>
		</div>
		</div>
		
		<Footer />
	</div>
    );
};

export default AboutUs;
