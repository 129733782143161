import Audiomerge from "./components/audiomerge";
import Audiotvideo from "./components/audiotvideo";
import Header from "./components/header";
import Homepage from "./components/homepage";
import {Routes,Route} from 'react-router-dom'
import Videotaudio from "./components/videotaudio";
import AudioUpload from "./components/AudioUpload";
import AudioCutter from "./components/audiocutter";
import AboutUs from "./components/AboutUs";
import Blogs from "./components/Blogs";
import FAQ from "./components/FAQ";
import PrivacyPolicy from "./components/PrivacyPolicy";


function App() {
  return (
    <div className="">
      <Routes>
        <Route path="/" element={<Homepage/>}/>
        <Route path="/audio-merge" element={<Audiomerge/>}/>
        <Route path="/audio-to-video" element={<Audiotvideo/>}/>
        <Route path='/video-to-audio' element={<Videotaudio/>}/>
        <Route path='/audio-merge-2' element={<AudioUpload/>}/>
		<Route path='/audio-cutter' element={<AudioCutter/>}/>
		<Route path='/about-us' element={<AboutUs/>}/>
		<Route path='/blogs' element={<Blogs/>}/>
		<Route path='/faq' element={<FAQ/>}/>	
		<Route path='/privacy-policy' element={<PrivacyPolicy/>}/>	
		
      </Routes>
    </div>
      
  );
}

export default App;
