import React, { useState, useRef, useEffect } from 'react';
import Image from '../assets/tch-logo.png';
import { RiArrowDropDownLine } from "react-icons/ri";
import { useNavigate, Link } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    // Add AdSense Script
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3170447905684027";
        script.async = true;
        script.crossOrigin = "anonymous";
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script); // Clean up on unmount
        };
    }, []);

    return (
        <header className='relative flex flex-col md:flex-row items-center justify-between gap-8 md:gap-32 h-[75px] px-4 md:px-36 font-roboto' style={{ backgroundColor: "#000000", color: '#FFFFFF' }}>
            {/* Logo */}
            <Link to="/" aria-label="Home">
                <div className="h-[75px] w-[90px] p-1 cursor-pointer">
                    <img src={Image} alt="Logo of the file converter" className="h-10 md:h-16" />
                </div>
            </Link>
            {/* Navigation Links */}
            <nav className="flex flex-col md:flex-row items-center gap-4 md:gap-12 mt-4 md:mt-0 text-sm md:text-md">
                <div className="relative" ref={dropdownRef}>
                    <div
                        className="px-4 py-2 hover:bg-gray-800 rounded transition-colors duration-300 cursor-pointer flex justify-center items-center"
                        onClick={toggleDropdown}
                        aria-haspopup="true"
                        aria-expanded={isDropdownOpen}
                    >
                        <div>Converters</div>
                        <RiArrowDropDownLine size={32} />
                    </div>
                    {isDropdownOpen && (
                        <div
                            className="absolute top-full left-0 mt-2 bg-gray-500 text-black rounded shadow-md z-20"
                            style={{ fontFamily: 'roboto', width: '250px' }}
                        >
                            <div
                                className="px-4 py-2 cursor-pointer hover:bg-black text-white"
                                onClick={() => handleNavigation('/audio-to-video')}
                                role="menuitem"
                            >
                                Audio to Video
                            </div>
                            <div
                                className="px-4 py-2 cursor-pointer hover:bg-black text-white"
                                onClick={() => handleNavigation('/video-to-audio')}
                                role="menuitem"
                            >
                                Video to Audio
                            </div>
                            <div
                                className="px-4 py-2 cursor-pointer hover:bg-black text-white"
                                onClick={() => handleNavigation('/audio-merge-2')}
                                role="menuitem"
                            >
                                Audio Merge
                            </div>
                            <div
                                className="px-4 py-2 cursor-pointer hover:bg-black text-white"
                                onClick={() => handleNavigation('/audio-cutter')}
                                role="menuitem"
                            >
                                Audio Cutter
                            </div>
                        </div>
                    )}
                </div>
                <div className="relative">
                    <div
                        className="px-4 py-2 hover:bg-gray-800 rounded transition-colors duration-300 cursor-pointer flex justify-center items-center"
                        onClick={() => handleNavigation('/about-us')}
                    >
                        About Us
                    </div>
                </div>
                <div
                    onClick={() => handleNavigation('/blogs')}
                    className="px-4 py-2 hover:bg-gray-800 rounded transition-colors duration-300 cursor-pointer"
                >
                    Blogs
                </div>
            </nav>
        </header>
    );
};

export default Header;
