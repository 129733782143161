import React, { useState } from 'react'
import Image1 from '../assets/buttonimage1.png'
import { useNavigate } from 'react-router-dom'

const Servicebuttons = () => {
    const navigate = useNavigate()
    const handleNavigation = (path) => {
        navigate(path);
        // setIsDropdownOpen(false); // Close the dropdown after navigation
    };
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const handleAudioToVideo = () => {
        navigate('/audio-to-video')
    }
    const handleButtonClick = () => {
        navigate('/audio-to-video'); // Redirect to main page or any other page
    };
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setIsDropdownOpen(false); // Close dropdown after selecting an option
        // You can add logic here to redirect to a different page based on selected option
        switch (event.target.value) {
            case 'option1':
                navigate('/audio-to-video'); // Redirect to page 1
                break;
            case 'option2':
                navigate('/audio-to-video'); // Redirect to page 2
                break;
            case 'option3':
                navigate('/audio-to-video'); // Redirect to page 3
                break;
            default:
                break;
        }
    };


    return (
        <div className='mt-[75px] z-10 relative'>
        <div className="flex justify-center mt-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-8 w-full md:w-[95%]" style={{ fontFamily: 'roboto' }}>
            
            {/* Button 1 */}
            <div className="flex justify-center">
              <button
                className='relative flex justify-between items-center w-full text-white py-3 md:py-4 px-2 rounded-full'
                style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}
                onClick={() => handleNavigation('/audio-to-video')}
              >
                <div className="flex gap-2 md:gap-4 items-center">
                  <div className="bg-white rounded-full p-1 md:p-0">
                    <img className='h-6 w-6 md:h-8 md:w-8 rounded-full' src={Image1} alt="icon" />
                  </div>
                  <div className="text-xs md:text-sm py-2">AUDIO TO VIDEO</div>
                </div>
              </button>
            </div>
            
            {/* Button 2 */}
            <div className="flex justify-center">
              <button
                className='relative flex justify-between items-center w-full text-white py-3 md:py-4 px-2 rounded-full'
                style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}
                onClick={() => handleNavigation('/video-to-audio')}
              >
                <div className="flex gap-2 md:gap-4 items-center">
                  <div className="bg-white rounded-full p-1 md:p-0">
                    <img className='h-6 w-6 md:h-8 md:w-8 rounded-full' src={Image1} alt="icon" />
                  </div>
                  <div className="text-xs md:text-sm py-2">VIDEO TO AUDIO</div>
                </div>
              </button>
            </div>
  
            {/* Button 3 */}
            <div className="flex justify-center">
              <button
                className='relative flex justify-between items-center w-full text-white py-3 md:py-4 px-2 rounded-full'
                style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}
                onClick={() => handleNavigation('/audio-merge-2')}
              >
                <div className="flex gap-2 md:gap-4 items-center">
                  <div className="bg-white rounded-full p-1 md:p-0">
                    <img className='h-6 w-6 md:h-8 md:w-8 rounded-full' src={Image1} alt="icon" />
                  </div>
                  <div className="text-xs md:text-sm py-2">MERGE AUDIO</div>
                </div>
              </button>
            </div>
  
            {/* Button 4 */}
            <div className="flex justify-center">
              <button
                className='relative flex justify-between items-center w-full text-white py-3 md:py-4 px-2 rounded-full'
                style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}
                onClick={() => handleNavigation('/audio-cutter')}
              >
                <div className="flex gap-2 md:gap-4 items-center">
                  <div className="bg-white rounded-full p-1 md:p-0">
                    <img className='h-6 w-6 md:h-8 md:w-8 rounded-full' src={Image1} alt="icon" />
                  </div>
                  <div className="text-xs md:text-sm py-2">AUDIO CUTTER</div>
                </div>
              </button>
            </div>
  
            {/* Button 5 */}
            <div className="flex justify-center">
              <button
                className='relative flex justify-between items-center w-full text-white py-3 md:py-4 px-2 rounded-full'
                style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}
                onClick={handleButtonClick}
              >
                <div className="flex gap-2 md:gap-4 items-center">
                  <div className="bg-white rounded-full p-1 md:p-0">
                    <img className='h-6 w-6 md:h-8 md:w-8 rounded-full' src={Image1} alt="icon" />
                  </div>
                  <div className="text-xs md:text-sm py-2">INCREASE VOLUME</div>
                </div>
              </button>
            </div>
  
            {/* Button 6 */}
            <div className="flex justify-center">
              <button
                className='relative flex justify-between items-center w-full text-white py-3 md:py-4 px-2 rounded-full'
                style={{ background: 'linear-gradient(to right, #AAABAB, #454545)' }}
                onClick={handleButtonClick}
              >
                <div className="flex gap-2 md:gap-4 items-center">
                  <div className="bg-white rounded-full p-1 md:p-0">
                    <img className='h-6 w-6 md:h-8 md:w-8 rounded-full' src={Image1} alt="icon" />
                  </div>
                  <div className="text-xs md:text-sm py-2">COMPRESS AUDIO VIDEO</div>
                </div>
              </button>
            </div>
  
          </div>
        </div>
      </div>
    )
}

export default Servicebuttons
