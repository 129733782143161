import React, { useState } from 'react';
import Headers from './headers';
import Footer from './footer';
import Image from '../assets/bg-image.jpeg';

const Videotaudio = () => {
    const [selectedVideoFile, setSelectedVideoFile] = useState(null);
    const [outputAudioPath, setOutputAudioPath] = useState('');
    const [loading, setLoading] = useState(false);
    const [conversionSuccess, setConversionSuccess] = useState(false);
    const [videoURL, setVideoURL] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedVideoFile(file);
            setVideoURL(URL.createObjectURL(file)); // Set video URL for preview
        }
    };

    const handleConvert = async () => {
        if (!selectedVideoFile) return;

        const formData = new FormData();
        formData.append('file', selectedVideoFile);
        formData.append('format', 'wav');

        setLoading(true);
        setConversionSuccess(false);

        try {
            const response = await fetch('http://localhost:5000/api/v1/video-to-audio-convert', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.status === 'success') {
                setOutputAudioPath(`http://localhost:5000${data.audio_file}`);
                setConversionSuccess(true);
            }
        } catch (error) {
            console.error('Error during fetch:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Headers />
            <div className="relative px-4 py-10 md:px-10 lg:px-24 xl:px-36">
                <div className="relative h-auto w-full rounded-md overflow-hidden">
                    <div
                        className="absolute inset-0"
                        style={{
                            backgroundImage: `url(${Image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            opacity: 0.3,
                            zIndex: -1,
                        }}
                    />
                    <div className="relative z-10 py-8 px-4">
                        <div className="text-3xl md:text-4xl lg:text-5xl font-black text-center" style={{ fontFamily: 'Lexend Deca' }}>
                            Online Video To Audio Converter
                        </div>
                        <div className="h-[2px] w-full bg-gray-500 my-4"></div>
                        <div className="py-5">
                            <table className="w-full max-w-[900px] border-separate border-spacing-4 mx-auto">
                                <tbody>
                                    <tr>
                                        <td className="text-xl font-normal text-start font-lexend text-[#313131]">
                                            Video File
                                        </td>
                                        <td className="flex items-center gap-4">
                                            <input
                                                type="file"
                                                accept="video/*"
                                                onChange={handleFileChange}
                                                className="hidden"
                                                id="video-file"
                                            />
                                            <button
                                                onClick={() => document.getElementById('video-file').click()}
                                                className='px-4 py-2 text-white rounded-md'
                                                style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
                                            >
                                                Choose Files
                                            </button>
                                            <div>{selectedVideoFile ? selectedVideoFile.name : 'No Chosen Files'}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-xl font-normal text-start font-lexend" style={{ color: '#313131' }}>
                                            Select Output Format
                                        </td>
                                        <td className="flex items-center gap-4">
                                            <select
                                                className="px-4 py-2 text-white rounded-md"
                                                style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
                                            >
                                                <option value="wav">WAV</option>
                                                <option value="mp3">MP3</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {videoURL && (
                            <div className="mt-4 flex justify-center">
                                <div className="w-full max-w-[500px]">
                                    <h3 className="text-lg font-semibold text-center">Video Preview:</h3>
                                    <video controls className="w-full mt-2" src={videoURL}>
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        )}

                        <div className="mt-10 flex justify-center">
                            <button 
                                className='bg-green-500 px-14 py-2 text-white rounded-md font-semibold' 
                                style={{ fontFamily: 'Lexend Deca' }} 
                                onClick={handleConvert}
                            >
                                Convert
                            </button>
                        </div>



                        {conversionSuccess && (
                            <div className="mt-8 flex flex-col items-center">
                                <h2 className="text-xl font-bold">Conversion Successful!</h2>
                                <div className="mt-4 text-center">
                                    <a 
                                        href={outputAudioPath} 
                                        download 
                                        className="mt-2 inline-block px-4 py-2 text-white bg-gray-900 rounded-md"
                                    >
                                        Download Audio
                                    </a>
                                    <audio controls className="mt-2">
                                        <source src={outputAudioPath} type="audio/wav" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>
                            </div>
                        )}

                        <div className="mt-16 text-2xl px-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
                            How To Use:
                        </div>
                        <div className="mt-4 px-10">
                            <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
                                <li>Select a video file to upload.</li>
                                <li>Click the "Convert" button to start the conversion.</li>
                                <li>Once the conversion is complete, download the audio file or preview it.</li>
                            </ol>
                        </div>

                        <div className="mt-16 text-2xl px-10 mb-10" style={{ fontFamily: 'Lexend Deca', color: '#313131' }}>
                            Remarks:
                        </div>
                        <div className="mt-4 px-10">
                            <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
                                <li>The maximum upload file size is 200 MB</li>
                                <li>Ensure you agree to the terms of this website before uploading.</li>
                                <li>If the upload process is slow or unresponsive, try canceling and resubmitting.</li>
                                <li>This converter cannot support encrypted or protected video files.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

            {/* Loader */}
            {loading && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <img
                        src="https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca_w200.gif"
                        alt="Loading"
                        className="w-16 h-16" // Adjust size as needed
                    />&nbsp;&nbsp;
                    <div className="text-white text-2xl mr-4">Processing...</div>			
                </div>
            )}
        </div>
    );
};

export default Videotaudio;
