import React, { useState } from 'react';
import Headers from './headers';
import Image from '../assets/bg-image.jpeg'; // Ensure this path is correct
import NatureImage from '../assets/audio2video/nature-image.jpeg'; // Replace with actual path
import DefaultImage from '../assets/audio2video/default-image.jpeg'; // Replace with actual path
import Footer from './footer';

const Audiotvideo = () => {
  const [selectedAudioFiles, setSelectedAudioFiles] = useState([]);
  const [selectedBackgroundType, setSelectedBackgroundType] = useState('none');
  const [selectedBackgroundFile, setSelectedBackgroundFile] = useState(null);
  const [outputVideoPath, setOutputVideoPath] = useState('');
  const [audioURL, setAudioURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [backgroundPreviewURL, setBackgroundPreviewURL] = useState(null);
  
  const MAX_FILENAME_LENGTH = 20;

  const truncateFilename = (name) => {
    return name.length > MAX_FILENAME_LENGTH
      ? `${name.substring(0, MAX_FILENAME_LENGTH)}...`
      : name;
  };

  const handleAudioFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedAudioFiles(files);
    if (files.length > 0) {
      const url = URL.createObjectURL(files[0]);
      setAudioURL(url);
    } else {
      setAudioURL(null);
    }
  };

  const handleBackgroundTypeChange = (event) => {
    const value = event.target.value;
    setSelectedBackgroundType(value);
    setSelectedBackgroundFile(null); // Clear file selection on type change

    // Set preview URL for static images or clear it
    if (value === 'nature') {
      setBackgroundPreviewURL(NatureImage);
    } else if (value === 'default') {
      setBackgroundPreviewURL(DefaultImage);
    } else if (value === 'none') {
      setBackgroundPreviewURL(null); // No preview for "none"
    }
  };

  const handleBackgroundFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedBackgroundFile(file);
      setBackgroundPreviewURL(URL.createObjectURL(file)); // Set preview URL for custom image
    }
  };

  const handleConvert = async () => {
    const formData = new FormData();
    if (selectedAudioFiles.length > 0) {
      formData.append('audio_file', selectedAudioFiles[0]);
    }
    if (selectedBackgroundFile) {
      formData.append('image_file', selectedBackgroundFile);
    }
    if (selectedBackgroundType) {
      formData.append('bg_type', selectedBackgroundType);
    }

    // Log FormData entries
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    setLoading(true);

    try {
      const response = await fetch('http://127.0.0.1:8000/api/v1/audio-to-video/', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error: ${response.status} - ${errorText}`);
      }

      const data = await response.json();
      console.log('Response from server:', data);

      if (data.file_path) {
        setOutputVideoPath(data.file_path);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      alert(`Conversion failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Headers />
      <div className="relative px-4 py-10 md:px-10 lg:px-24 xl:px-36 mb-40">
        <div className="relative h-auto w-full rounded-md overflow-hidden">
          <div
            className="absolute inset-0"
            style={{
              backgroundImage: `url(${Image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              opacity: 0.3,
              zIndex: -1,
            }}
          />
          <div className="relative z-10 py-8 px-4">
            <div className="text-3xl md:text-4xl lg:text-5xl font-black text-center" style={{ fontFamily: 'Lexend Deca' }}>
              Online Audio To Video Converter
            </div>
            <div className="h-[2px] w-full bg-gray-500 my-4"></div>
            <div className="py-5">
              <table className="w-full max-w-[900px] border-separate border-spacing-4 mx-auto">
                <tbody>
                  <tr>
                    <td className="text-xl font-normal text-start font-lexend" style={{ color: '#313131' }}>
                      Audio File
                    </td>
                    <td className="flex items-center gap-4">
                      <input
                        type="file"
                        accept="audio/*"
                        id="audio-file"
                        className="hidden"
                        onChange={handleAudioFileChange}
                      />
                      <button
                        onClick={() => document.getElementById('audio-file').click()}
                        className="px-4 py-2 text-white rounded-md"
                        style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca', minWidth: '120px' }}
                      >
                        Choose Files
                      </button>
                      <div className="overflow-hidden text-ellipsis whitespace-nowrap ml-4">
                        {selectedAudioFiles.length > 0
                          ? selectedAudioFiles.map((file, index) => (
                              <div key={index}>{truncateFilename(file.name)}</div>
                            ))
                          : 'No File Chosen'}
                      </div>
                    </td>
                  </tr>
                  {audioURL && (
                    <tr>
                      <td colSpan="2" className="pt-4">
                        <audio controls src={audioURL} className="w-full">
                          Your browser does not support the audio element.
                        </audio>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="text-xl font-normal text-start font-lexend" style={{ color: '#313131' }}>
                      Select Background Type
                    </td>
                    <td className="flex items-center gap-4">
                      <select
                        name="bg_type"
                        className="px-4 py-2 text-white rounded-md"
                        style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca' }}
                        onChange={handleBackgroundTypeChange}
                        value={selectedBackgroundType}
                      >
                        <option value="none">None</option>
                        <option value="default">Default</option>
                        <option value="nature">Nature</option>
                        <option value="image">Image</option>
                      </select>
                      {/* Image Preview */}
                      {backgroundPreviewURL && (
                        <img
                          src={backgroundPreviewURL}
                          alt="Background Preview"
                          className="mt-2 w-32 h-32 object-cover border border-gray-300 rounded"
                        />
                      )}
                    </td>
                  </tr>
                  {selectedBackgroundType === 'image' && (
                    <tr>
                      <td className="text-xl font-normal text-start font-lexend" style={{ color: '#313131' }}>
                        Select Background
                      </td>
                      <td className="flex items-center gap-4">
                        <input
                          type="file"
                          accept="image/*"
                          id="background-file"
                          className="hidden"
                          onChange={handleBackgroundFileChange}
                        />
                        <button
                          onClick={() => document.getElementById('background-file').click()}
                          className="px-4 py-2 text-white rounded-md"
                          style={{ backgroundColor: '#2C2C2C', fontFamily: 'Lexend Deca', minWidth: '120px' }}
                        >
                          Choose Files
                        </button>
                        <div className="overflow-hidden text-ellipsis whitespace-nowrap ml-4">
                          {selectedBackgroundFile ? selectedBackgroundFile.name : 'No File Chosen'}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="mt-10 flex justify-center">
              <button className="px-14 py-2 text-white rounded-md font-medium border hover:border-black hover:shadow-md" onClick={handleConvert} style={{ backgroundColor: '#14AE5C' }}>
                Convert
              </button>
            </div>

            {outputVideoPath && (
              <div className="mt-8 flex justify-center">
                <div className="w-3/4 max-w-[500px]">
                  <h2 className="text-xl font-bold">Conversion Successful!</h2>
                  <p className="mt-2">Your video has been created:</p>
                  <video controls className="w-full h-auto mt-4 rounded-md shadow-md">
                    <source src={outputVideoPath} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <a href={outputVideoPath} download className="mt-4 inline-block px-4 py-2 text-white bg-blue-500 rounded-md">
                    Download Video
                  </a>
                </div>
              </div>
            )}

            <div className="mt-16 text-2xl font-light px-10 font-lexend" style={{ color: '#313131' }}>
              How To Use:
            </div>

            <div className="mt-4 px-10">
              <ol className="list-decimal pl-6 font-lexend">
                <li>Select a value in the "Background". If you choose the "Image File" option, select an "Image File" (such as *.jpg, *.bmp, *.gif, *.png, and more).</li>
                <li>Click the "Convert" button to start uploading your files.</li>
                <li>Once the upload is complete, the converter will redirect to a web page to display the conversion results.</li>
                <li>Follow any additional instructions provided on the conversion results page to finalize the process.</li>
              </ol>
            </div>

            <div className="mt-16 text-2xl font-extralight px-10 mb-10 font-lexend" style={{ color: '#313131' }}>
              Remarks:
            </div>

            <div className="mt-4 px-10">
              <ol className="list-decimal pl-6" style={{ fontFamily: 'Lexend Deca' }}>
                <li>The maximum upload file size is 200 MB.</li>
                <li>Before uploading, please make sure you agree to the terms of this website.</li>
                <li>If the file upload process takes a long time or is unresponsive or very slow, please try to cancel and resubmit.</li>
                <li>This converter cannot support encrypted or protected audio files.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <img
            src="https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca_w200.gif"
            alt="Loading"
            className="w-16 h-16"
          />
          <div className="text-white text-2xl mr-4">Processing...</div>
        </div>
      )}
    </div>
  );
};

export default Audiotvideo;
