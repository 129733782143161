import React, { useState } from 'react';
import Headers from './headers';
import Image from '../assets/bg-image.jpeg';
import CommongSoonImage from '../assets/ComingSoon.gif';
import Footer from './footer';

const AudioCutter = () => {
    return (
        <div>
            <Headers />
            <div className="relative px-4 py-10 md:px-10 lg:px-24 xl:px-36">
                {/* Container for content */}
                <div className="relative h-auto w-full rounded-md overflow-hidden">
                    {/* Background image with opacity */}
                    <div
                        className="absolute inset-0"
                        style={{
                            backgroundImage: `url(${Image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            opacity: 0.3,
                            zIndex: -1,
                        }}
                    />
                    <div className="relative z-10 py-8 px-4">
  <div className="text-3xl md:text-4xl lg:text-5xl font-black text-center" style={{ fontFamily: 'Lexend Deca' }}>
    Online Audio Cutter
  </div>
  <div className="h-[2px] w-full bg-gray-500 my-4"></div>
  <div className="py-5 flex justify-center">
    <img src={CommongSoonImage} alt="Coming Soon" className="max-w-full h-auto" />
  </div>
</div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AudioCutter;

