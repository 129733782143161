import React, { useState } from 'react';
import Image from '../assets/format.png';
import '../App.css';
import Footer from './footer';
import { useNavigate } from 'react-router-dom';
import CartoonImage from '../assets/cartoonimage.png';
import CurveImage from '../assets/curveimage.png';
import Card from './card';
import Servicebuttons from './servicebuttons';
import Header from './headers';

const CustomDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Select Target Format');

    const handleDropdownToggle = () => setIsOpen(!isOpen);

    const handleOptionSelect = (value) => {
        setSelectedOption(value);
        setIsOpen(false);
    };

    return (
        <div className="relative inline-block w-full max-w-[420px] mt-10">
            <div className="flex items-center justify-between bg-gradient-to-r from-gray-400 to-gray-600 text-white rounded-full p-4 shadow-lg cursor-pointer" onClick={handleDropdownToggle}>
                <div className="flex items-center space-x-4">
                    <img src={Image} alt="Format selection icon" className="w-7 h-7" />
                    <span className="text-sm font-medium">{selectedOption}</span>
                </div>
                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
            </div>
            {isOpen && (
                <div className="absolute z-10 bg-white text-black rounded-md px-1 shadow-lg mt-2 w-full max-w-[420px]">
                    <div className="flex flex-col p-4 space-y-2">
                        {Array.from({ length: 4 }, (_, index) => (
                            <div key={index} className="flex items-center justify-between bg-gradient-to-r from-gray-400 to-gray-600 text-white rounded-full p-4 shadow-lg cursor-pointer" onClick={() => handleOptionSelect(`Option ${index + 1}`)}>
                                <div className="flex items-center space-x-4">
                                    <img src={Image} alt="Option icon" className="w-7 h-7" />
                                    <span className="text-sm font-medium">Option {index + 1}</span>
                                </div>
                                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                </svg>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

const Homepage = () => {
    const navigate = useNavigate();
    const handleChange = (event) => {
        const selectedOption = event.target.value;
        switch (selectedOption) {
            case 'option1':
                navigate('/audio-to-video');
                break;
            case 'option2':
                navigate('/video-to-audio');
                break;
            case 'option3':
                navigate('/audio-merge');
                break;
            case 'option4':
                navigate('/audio-cutter');
                break;
            case 'option5':
                navigate('/increase-volume');
                break;
            case 'option6':
                navigate('/compress-audio-video');
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Header />
            <div className="p-4 md:p-8 lg:p-16 xl:p-20 flex flex-col lg:flex-row items-center">
                <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start mb-8 lg:mb-0">
                    <div className="flex flex-col items-center lg:items-start">
                        <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-extrabold" style={{ fontFamily: 'Lexend Deca' }}>
                            Free Online File Converter
                        </h1>
                        <p className="mt-2 md:mt-4 lg:mt-6 xl:mt-8 text-base md:text-lg lg:text-xl text-gray-800 text-center lg:text-left">
                            Easily convert videos, documents, images, audio, PDF files, and more with our all-in-one converter.
                        </p>

                        <select
                            id="options"
                            className='border-2 border-black w-full md:w-[60%] mt-8 h-[50px] rounded-xl px-4 text-black text-md font-semibold'
                            onChange={handleChange}
                        >
                            <option value="">Select Converter</option>
                            <option value="option1">Audio to Video</option>
                            <option value="option2">Video to Audio</option>
                            <option value="option3">Merge Audio</option>
                            <option value="option4">Audio Cutter</option>
                        </select>
                    </div>

                    <div className="w-full max-w-md lg:max-w-lg h-auto mt-8 lg:mt-0 lg:ml-10">
                        <img src={CartoonImage} alt="Converter illustration" className="w-full h-auto object-contain" />
                    </div>
                </div>
            </div>

            <div className="">
                <img className='h-[60px] w-full mb-20' src={CurveImage} alt="Decorative curve" />
            </div>

            <Servicebuttons />
            <Card />
            <Footer />
        </>
    );
};

export default Homepage;
