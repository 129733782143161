import React, { useState, useRef, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './AudioUpload.css';
import Headers from './headers';
import Footer from './footer';

const AudioUpload = () => {
    const [audios, setAudios] = useState([]);
    const [draggingId, setDraggingId] = useState(null);
    const [currentAudio, setCurrentAudio] = useState(null);
    const [mergedAudioUrl, setMergedAudioUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);
    const MAX_FILENAME_LENGTH = 50;

    const truncateFilename = (name) => {
        return name.length > MAX_FILENAME_LENGTH
            ? `${name.substring(0, MAX_FILENAME_LENGTH)}...`
            : name;
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        
        if (files.length < 2) {
            alert("You must upload at least two audio files.");
            return;
        }

        const newAudios = files.map(file => ({
            name: truncateFilename(file.name),
            url: URL.createObjectURL(file),
            id: `${file.name}-${Date.now()}`,
            file: file  // Store the original file reference
        }));
        
        setAudios(prev => [...prev, ...newAudios]);
    };

    const onDragEnd = (result) => {
        setDraggingId(null);
        if (!result.destination) return;

        const reorderedAudios = Array.from(audios);
        const [movedAudio] = reorderedAudios.splice(result.source.index, 1);
        reorderedAudios.splice(result.destination.index, 0, movedAudio);
        setAudios(reorderedAudios);
    };

    useEffect(() => {
        return () => {
            audios.forEach(audio => URL.revokeObjectURL(audio.url));
        };
    }, [audios]);

    const removeAudio = (id) => {
        setAudios(prev => prev.filter(audio => audio.id !== id));
        if (currentAudio && currentAudio.id === id) {
            setCurrentAudio(null);
        }
    };

    const handlePlayAudio = (audio) => {
        if (currentAudio && currentAudio.id !== audio.id) {
            const audioElement = document.getElementById(currentAudio.id);
            if (audioElement) {
                audioElement.pause();
            }
        }
        setCurrentAudio(audio);
    };

    const handleConvert = async () => {
        if (audios.length < 2) {
            alert("Please upload at least two audio files.");
            return;
        }

        setIsLoading(true);
        const formData = new FormData();
        audios.forEach(audio => {
            formData.append('files', audio.file);
        });

        try {
            const response = await fetch('http://localhost:9000/api/v1/merge-audios/', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (response.ok) {
                // Handle successful response, set merged audio URL for preview
                setMergedAudioUrl(data.url);
            } else {
                console.error(data.error);
                alert("Error merging audio files: " + data.error);
            }
        } catch (error) {
            console.error("Error:", error);
            alert("An unexpected error occurred.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Headers />
            <div className="relative px-4 py-10 md:px-10 lg:px-24 xl:px-36">
                <div className="relative h-auto w-full rounded-md overflow-hidden">
                    <div className="relative z-10 py-8 px-4">
                        <div className="text-3xl md:text-4xl lg:text-5xl font-black text-center">
                            Online Audio Merge
                        </div>
                        <div className="h-[2px] w-full bg-gray-500 my-4"></div>

                        <div className="audio-upload-container text-center">
                            <input
                                type="file"
                                accept="audio/*"
                                multiple
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                className="hidden"
                                id="audio-upload-input"
                            />
                            <button
                                onClick={() => fileInputRef.current.click()}
                                className="text-center bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-6 rounded-md transition-all duration-200"
                            >
                                <i className="fa fa-upload mr-2" aria-hidden="true"></i> Choose Audios (Multiple)
                            </button>

                            <DragDropContext onDragEnd={onDragEnd} onDragStart={(start) => setDraggingId(start.draggableId)}>
                                <Droppable droppableId="audios" type="group">
                                    {(provided) => (
                                        <ul className="audio-list" {...provided.droppableProps} ref={provided.innerRef}>
                                            {audios.map((audio, index) => (
                                                <Draggable key={audio.id} draggableId={audio.id} index={index}>
                                                    {(provided) => (
                                                        <li
                                                            className={`audio-item ${draggingId === audio.id ? 'dragging' : ''}`}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <audio
                                                                id={audio.id}
                                                                controls
                                                                src={audio.url}
                                                                className="audio-preview"
                                                                onPlay={() => handlePlayAudio(audio)}
                                                            />
                                                            <span className="audio-name">{audio.name}</span>
                                                            <button onClick={() => removeAudio(audio.id)} className="remove-audio-button bg-gray-500">
                                                                <span role="img" aria-label="delete">🗑️</span>
                                                            </button>
                                                        </li>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>

                        <div className="mt-10 flex justify-center">
                            <button
                                className={`bg-green-500 px-14 py-2 text-white rounded-md font-semibold ${audios.length < 2 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                onClick={handleConvert}
                                disabled={audios.length < 2}
                            >
                                {isLoading ? "Converting..." : "Convert"}
                            </button>
                        </div>

                        {isLoading && <div className="loader">Loading...</div>}

                        {mergedAudioUrl && (
                            <div className="mt-10 text-center">
                                <audio controls src={mergedAudioUrl} className="audio-preview" />
                                <div className="mt-4">
                                    <a href={mergedAudioUrl} download className="bg-blue-500 text-white py-2 px-4 rounded-md">
                                        Download Merged Audio
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AudioUpload;
